<template>
  <v-row>
    <v-col cols="3">
      <CardMiniatureTile></CardMiniatureTile>
    </v-col>
    <v-col cols="3">
      <CardMiniatureTile></CardMiniatureTile>
    </v-col>
    <v-col cols="3">
      <CardMiniatureTile></CardMiniatureTile>
    </v-col>
    <v-col cols="3">
      <CardMiniatureTile></CardMiniatureTile>
    </v-col>
  </v-row>
</template>

<script>
import CardMiniatureTile from '../../components/property/CardMiniatureTile'
export default {
  name: 'PromoCards',
  components: { CardMiniatureTile }
}
</script>

<style scoped>

</style>
