<template>
  <div>
    <div id="top-background"></div>
    <v-container fluid id="search-form">
      <h1 id="color-white">Vaše dovolená 2022</h1>
      <SimpleSearchForm id="simple-search" @search-init="search"></SimpleSearchForm>
    </v-container>
    <div>
      <v-container>
        <div class="text-center">
          <span class="small-blue">Dovolená</span>
          <div class="display-1 py-4">Ostatní destinace</div>
          <span class="heading-subtitle">Lorem ipsum dolor sit amet</span>
        </div>
          <v-row>
            <v-col cols="4">
              <CardMiniatureTile></CardMiniatureTile>
            </v-col>
            <v-col cols="4">
              <CardMiniatureTile></CardMiniatureTile>
            </v-col>
            <v-col cols="4">
              <CardMiniatureTile></CardMiniatureTile>
            </v-col>
          </v-row>
      </v-container>
    </div>
    <div id="ad-property-cards">
      <v-container>
        <div class="small-blue">Inspirace</div>
        <div class="display-1 mb-2">Právě v nabídce</div>
        <span class="heading-subtitle">Lorem ipsum dolor sit amet</span>
        <PromoCards></PromoCards>
      </v-container>
    </div>
  </div>
</template>

<script>
import SimpleSearchForm from '@/components/search/SimpleSearchForm'
import CardMiniatureTile from '../../components/property/CardMiniatureTile'
import PromoCards from '../../components/promo/PromoCards'
export default {
  name: 'Homepage',
  components: { PromoCards, CardMiniatureTile, SimpleSearchForm },
  methods: {
    search (parameters) {
      this.$router.push({
        name: 'search-result',
        query: parameters
      })
    }
  }
}
</script>

<style scoped>
.small-blue{
  color: var(--v-hotelsBlue-base);
  font-family: 'Readex Pro', sans-serif;
  text-transform: uppercase;
  font-size: 15px;
  margin-bottom: 10px;
}
.display-1{
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 40px;
}
.heading-subtitle{
  font-size: 17px;
  color: #6a6a6a;
  font-family: 'Readex Pro', sans-serif;
}
#search-form{
  max-width: 1450px;
}
#color-white{
  color: white;
  padding-bottom: 5vh;
  font-family: "Roboto", sans-serif !important;
}
#search-form{
  margin-top: -25vh;
  padding-bottom: 5vh;
}
#top-background{
  background-image: url("../../assets/homepage-bg.png");
  background-position: 50% 55%;
  object-fit: contain;
  height: 50vh;
  background-size: cover;
}
>>>#ad-property-cards{
  background-color: #edf9ff;
  padding-top: 50px;
  padding-bottom: 50px;
}
</style>
